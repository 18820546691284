<script setup>
import { useChatStore } from "@/stores/chat.store";
import { useUserStore } from "@/stores/user.store";
import { computed, inject, onMounted, ref } from "vue";

const chatService = inject("chatService");

const chatStore = useChatStore();
const userStore = useUserStore();

const storedUser = computed(() => ({
  id: userStore.getUserId,
}));
const currentChat = computed(() => chatStore.getCurrentChat);
const receiver = computed(() => ({
  id:
    currentChat.value.senderID === storedUser.value.id
      ? currentChat.value.receiverID
      : currentChat.value.senderID,
}));
const _message = computed(() =>
  !!chatStore.getNote && chatStore.getNote.message
    ? chatStore.getNote.message
    : ""
);
const message = computed({
  get: () => chatStore.getActiveNote,
  set: (v) => chatStore.setActiveNote(v),
});

const fetchNote = () => {
  chatService.getUserNoteById({
    userId: receiver.value.id,
  });
};

const sendNote = ({ msg }) => {
  chatService.saveUserNote({
    userId: receiver.value.id,
    note: !!msg ? msg : message.value,
  });
  setTimeout(() => {
    fetchNote();
  }, 300);
};

const clearNote = () => {
  sendNote("");
};

onMounted(() => {
  setTimeout(() => {
    fetchNote();
  }, 1000);
});
</script>

<template>
  <div class="chat-user-note-view-body">
    <a-alert
      message="Bilgilendirme"
      description="Bu alanda yazacağınız notları yalnızca siz görebilirsiniz."
      type="info"
      class="mb-6 mt-1"
    >
    </a-alert>
    <a-textarea
      max-length="1000"
      placeholder="Notunuzu buraya yazın..."
      :auto-size="{ minRows: 5, maxRows: 18 }"
      v-model:value="message"
      class="p-3"
    ></a-textarea>
  </div>
  <div class="chat-user-note-view-footer">
    <transition name="fade" mode="out-in">
      <div class="chat-user-note-action-group">
        <a-row :gutter="[20, 0]">
          <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" :xxl="12">
            <a-button
              @click="sendNote"
              class="purple-button"
              block
              :disabled="
                !(message && message.length > 0 && _message !== message)
              "
              >Kaydet</a-button
            >
          </a-col>
          <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" :xxl="12">
            <a-button
              @click="clearNote"
              class="secondary-button"
              block
              :disabled="_message !== message"
              >Sil</a-button
            >
          </a-col>
        </a-row>
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
.chat-user-note-view-footer {
  margin-top: 0.5rem;
  .chat-user-note-action-group {
    button {
      margin-top: 0.5rem;
    }
  }
}
</style>
